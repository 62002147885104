import React, { useState, useEffect, } from "react";
import { getDoc, getDocs, doc, setDoc, updateDoc, onSnapshot, collection, addDoc, Timestamp, deleteDoc, writeBatch, query, where } from 'firebase/firestore';
import { useNavigate, useParams, Link, useHistory, useNavigation } from 'react-router-dom';
import { getAuth, signOut, reauthenticateWithCredential, EmailAuthProvider, updatePassword } from "firebase/auth";
import { FaUser, FaArrowLeft, FaTv } from 'react-icons/fa';
import { db, app } from "../../firebase";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useLocation } from 'react-router-dom';
import moment, { weekdaysMin } from 'moment-timezone';
import { SubmitFighter } from "./AdminFighter";
import { UpdateFighter } from "./AdminFighter";
import { Menu } from "./AccueilAdmin";
import Logo from '../../assets/logo/logo-horizontale-v2.png';
import NoFighterImage from '../../assets/logo/no-image-men.png';
import '../../css/style.css'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { findCombattant } from "./Live";

export function Combat() {
    const [displayCombat, setDisplayCombat] = useState(0); // 1 = combat passé, 2 = combat à venir
    const { t } = useTranslation();
    /////// BOUTON
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate();
    const toggleMenu = () => {
        setIsMenuOpen(prevState => !prevState);
    };


    const auth = getAuth();

    const handleLogout = () => { // DÉCONNEXION
        signOut(auth).then(() => {
            navigate("/login");
        }).catch((error) => {
            console.error("Error logging out: ", error);
        });
    };
    return (

        <div style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: "#fcfaf5"
        }}>
            <div style={{
                position: 'absolute', top: '0', height: '60px', width: '100%', display: 'flex',
                justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#ff3030', padding: '0 20px'
            }}>
                <div style={{
                    display: 'flex', alignItems: 'center'
                }}>
                    <img src={Logo} alt="Logo" style={{ height: '250px' }} />
                    <h2 style={{ margin: 0, color: 'white', fontSize: '1.2em' }}>COMBAT</h2>
                </div>

                <div style={{
                    display: 'flex', alignItems: 'center'
                }}>
                    <button onClick={toggleMenu} style={{
                        padding: '8px 16px', border: 'none', backgroundColor: 'white', color: '#ff3030',
                        borderRadius: '25px', cursor: 'pointer', outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder', marginRight: '10px'
                    }}>
                        {isMenuOpen ? t('Close the menu') : t('Open the menu')}
                    </button>

                    <button onClick={handleLogout} style={{
                        background: 'none', border: '1px solid white', color: 'white', padding: '8px 16px',
                        borderRadius: '25px', cursor: 'pointer', fontWeight: 'bolder'
                    }}>
                        Logout
                    </button>
                </div>
            </div>


            {isMenuOpen && (
                <Menu />
            )}
            <div style={{ // DONNÉE FIGHTER
                marginTop: '100px', width: '100%', alignItems: 'center', justifyContent: 'center',
            }}>

                <div style={{ // BOUTONS
                    flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%',
                }}>
                    <button // 
                        onClick={() => setDisplayCombat(1)}
                        style={{
                            padding: '10px 20px', border: 'none', backgroundColor: 'white', border: '2px solid #ff3030',
                            color: displayCombat === 1 ? 'white' : 'black', borderRadius: 50, marginTop: '10px', cursor: 'pointer',
                            outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                            height: 50, width: '30%', fontSize: 15, backgroundColor: displayCombat === 1 ? '#ff3030' : 'white',

                        }}>
                        {t('Add a fight')}
                    </button>

                    <button // 
                        onClick={() => setDisplayCombat(2)}
                        style={{
                            padding: '10px 20px', border: 'none', backgroundColor: 'white', border: '2px solid #ff3030',
                            color: displayCombat === 2 ? 'white' : 'black', borderRadius: 50, marginTop: '10px', cursor: 'pointer',
                            outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                            height: 50, width: '30%', fontSize: 15, backgroundColor: displayCombat === 2 ? '#ff3030' : 'white', marginLeft: 20
                        }}>
                        {t("Update a fight")}
                    </button>
                </div>


                {displayCombat === 1 ? <SubmitCombat /> : null}
                {displayCombat === 2 ? <UpdateCombat /> : null}



            </div>

        </div>
    )
}

export function SubmitCombat() {
    const { t } = useTranslation();
    const [events, setEvents] = useState([]);
    const [eventName, setEventName] = useState("");
    const [eventData, setEventData] = useState([]);
    const [combatId, setCombatId] = useState("");
    const [nom1, setNom1] = useState("");
    const [nom2, setNom2] = useState("");
    const [numberOfRound, setNumberOfRound] = useState("");
    const [org, setOrg] = useState("");

    const years = ['2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030'];
    const currentYear = new Date().getFullYear();
    const [selectedYear, setSelectedYear] = useState(currentYear.toString());
    const [orgs, setOrgs] = useState([]);
    const cards = eventData.ifEarlyPrelims ? ['main card', 'prelims', 'early prelims'] : ['main card', 'prelims'];
    const [selectedCard, setSelectedCard] = useState('');
    const [time, setTime] = useState('');

    const ways = [{ value: 0, label: t("None") }, { value: 1, label: "KO/TKO" }, { value: 2, label: "Submission" }, { value: 3, label: "Decision" },
    { value: 3.1, label: "Unanimous Decision" }, { value: 3.2, label: "Split Décision" }, { value: 3.3, label: "Majority Decision" },
    { value: 4, label: "Draw" }, { value: 4.1, label: "Unanimous Draw" }, { value: 4.2, label: "Split Draw" }, { value: 4.3, label: "Majority Draw" },
    { value: 5, label: "NO CONTEST" }, { value: 6, label: "Doctor Stoppage" }, { value: 7, label: "DISQUALIFICATION" }, { value: 8, label: "Forfeit" },
    { value: 9, label: "Technical draw" }, { value: 10, label: "Technical decision" }];
    const [way, setWay] = useState(0);

    const winArray = [{ value: 1, label: `${nom1}` }, { value: 2, label: `${nom2}` }, { value: 3, label: 'DRAW' }, { value: 4, label: 'NO-CONTEST' }];
    const [win, setWin] = useState(0);

    const rounds = [{ value: 1, label: 'ROUND 1' }, { value: 2, label: 'ROUND 2' }, { value: 3, label: 'ROUND 3' }, { value: 4, label: 'ROUND 4' }, { value: 5, label: 'ROUND 5' }];
    const [round, setRound] = useState(0);

    const champ = [{ value: 0, label: "NORMAL" }, { value: 1, label: 'CHAMP' }, { value: 2, label: 'INTERIM CHAMP' }];
    const [ifChamp, setIfChamp] = useState(0);

    const main = [{ value: 0, label: "NORMAL" }, { value: 1, label: 'MAIN EVENT' }, { value: 2, label: 'CO MAIN EVENT' }];
    const [ifMain, setIfMain] = useState(0);

    const tournament = [{ value: 0, label: "aucun" }, { value: 1, label: 'FINAL' }, { value: 2, label: 'SEMI-FINAL' }, { value: 3, label: 'QUARTER-FINAL' }];
    const [ifTournament, setIfTournament] = useState(0);

    const orders = [{ value: "first", label: `first fight ${selectedCard}` }, { value: "1", label: `last fight ${selectedCard}` },
    { value: "2", label: `Second last combat ${selectedCard}` }, { value: "3", label: "3rd fight from the end" },
    { value: "4", label: "4th fight from the end" }, { value: "5", label: "5th fight from the end" },
    { value: "6", label: "6th fight from the end" }, { value: "7", label: "7th fight from the end" },
    { value: "8", label: "8th fight from the end" }, { value: "9", label: "9th fight from the end" },
    { value: "10", label: "10th fight from the end" }, { value: "11", label: "11th fight from the end" },
    { value: "12", label: "12th fight from the end" }, { value: "13", label: "13th fight from the end" },
    { value: "14", label: "14th fight from the end" }, { value: "15", label: "15th fight from the end" }];
    const [order, setOrder] = useState("");

    const divisions = [{ value: "1heavy", label: "HEAVYWEIGHT" },
    { value: "2lightheavy", label: "LIGHT-HEAVYWEIGHT" },
    { value: "3middle", label: "MIDDLEWEIGHT" },
    { value: "4welter", label: "WELTERWEIGHT" },
    { value: "5light", label: "LIGHTWEIGHT" },
    { value: "6feather", label: "FEATHERWEIGHT" },
    { value: "7bantam", label: "BANTAMWEIGHT" },
    { value: "8fly", label: "FLYWEIGHT" },
    { value: "9straw", label: "STRAWWEIGHT" },
    { value: "10atom", label: "ATOMWEIGHT" },
    { value: "catch", label: "CATCH WEIGHT" }];
    const [division, setDivision] = useState("");

    const [amateur, setAmateur] = useState(false);
    const amateurs = [{ value: false, label: "NO" }, { value: true, label: "YES" }];

    const roundTotal = [{ value: '3', label: '3 round' }, { value: '5', label: '5 round' }];

    const [endTime, setEndTime] = useState("");

    const navigate = useNavigate();

    useEffect(() => { // RÉCUPÉRATION ORGA
        const fetchOrgs = async () => {

            const orgsDocRef = doc(db, "fightData", "organisations");

            try {
                const orgsDocSnap = await getDoc(orgsDocRef);

                if (orgsDocSnap.exists()) {
                    const { listOrgasNames } = orgsDocSnap.data(); // Assurez-vous que 'listOrgasNames' est correctement initialisé dans votre document
                    setOrgs(listOrgasNames);
                } else {
                    console.log("Document 'organisations' non trouvé.");
                }
            } catch (error) {
                console.error("Erreur lors de la récupération du document:", error);
            }
        };

        fetchOrgs();
    }, []);

    ////////////// NOM COMBATTANTS //////////////
    function useDebounce(value, delay) { // DÉLAI POUR RECHERCHE
        const [debouncedValue, setDebouncedValue] = useState(value);

        useEffect(() => {
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            return () => {
                clearTimeout(handler);
            };
        }, [value, delay]);

        return debouncedValue;
    }

    const [search1, setSearch1] = useState('');
    const debouncedSearchTerm = useDebounce(search1, 500);  // Attend 500ms après la dernière frappe
    const [fighters1, setFighters1] = useState([]);
    const [filteredFighters1, setFilteredFighters1] = useState([]);

    const [search2, setSearch2] = useState('');
    const debouncedSearchTerm2 = useDebounce(search2, 500);  // Attend 500ms après la dernière frappe
    const [fighters2, setFighters2] = useState([]);
    const [filteredFighters2, setFilteredFighters2] = useState([]);
    // Créez une instance de l'index Algolia

    const fetchFighters1 = async (search1, orga, attributesToRetrieve = ['id']) => {
        const functions = getFunctions(app);
        const searchFighters = httpsCallable(functions, 'searchFighters');

        let fetchedData = [];

        try {
            // Créez une requête de recherche Algolia avec filtres
            const result = await searchFighters({
                search: search1,
                orga,
                attributesToRetrieve
            });

            // Ajoutez tous les résultats de recherche à fetchedData

            if (result.data && Array.isArray(result.data.hits)) {
                fetchedData = result.data.hits.map(hit => ({
                    id: hit.id,
                    ...hit
                }));
            } else {
                console.log('No results from Algolia search or data structure is unexpected.');
            }
        } catch (error) {
            console.error('Error loading favorite fighters data:', error);
        }


        return fetchedData;
    };

    const fetchFighters2 = async (search2, orga, attributesToRetrieve = ['id']) => {
        const functions = getFunctions(app);
        const searchFighters = httpsCallable(functions, 'searchFighters');

        let fetchedData = [];

        try {
            // Créez une requête de recherche Algolia sans filtres
            const result = await searchFighters({
                search: search2,
                orga,
                attributesToRetrieve
            });

            // Ajoutez tous les résultats de recherche à fetchedData
            if (result.data && Array.isArray(result.data.hits)) {
                fetchedData = result.data.hits.map(hit => ({
                    id: hit.id,
                    ...hit
                }));
            } else {
                console.log('No results from Algolia search or data structure is unexpected.');
            }
        } catch (error) {
            console.error('Error loading favorite fighters data:', error);
        }


        return fetchedData;
    };

    ////////////// NOM1
    useEffect(() => {
        if (debouncedSearchTerm) {
            const fetchFightersData = async () => {

                const fetchedData = await fetchFighters1(debouncedSearchTerm, org);
                setFighters1(fetchedData);
                setFilteredFighters1(fetchedData);
            };
            fetchFightersData();
        }
    }, [debouncedSearchTerm]);

    useEffect(() => {
        const results = fighters1.filter(fighter => fighter.id && fighter.id.toLowerCase().includes(search1.toLowerCase()));
        setFilteredFighters1(results);
    }, [search1]);

    ////////////// NOM2
    useEffect(() => {
        if (debouncedSearchTerm2) {
            const fetchFightersData = async () => {
                const fetchedData = await fetchFighters2(debouncedSearchTerm2, org);;
                setFighters2(fetchedData);
                setFilteredFighters2(fetchedData);
            };
            fetchFightersData();
        }
    }, [debouncedSearchTerm2]);

    useEffect(() => {
        const results = fighters2.filter(fighter => fighter.id && fighter.id.toLowerCase().includes(search2.toLowerCase()));
        setFilteredFighters2(results);
    }, [search2]);

    /////////////////////////

    // Charger tous les events
    useEffect(() => {

        const fetchEvents = async () => {
            if (selectedYear && org) {
                const querySnapshot = await getDocs(collection(db, "fightData", "organisations", org, "events", selectedYear));
                const events = [];
                querySnapshot.forEach((document) => {
                    const data = document.data();
                    events.push({
                        id: document.id,               // Document ID
                        displayEventName: data.displayEventName || document.id  // Fallback to ID if displayEventName doesn't exist
                    });
                });
                setEvents(events);
            };
        };
        fetchEvents();


    }, [selectedYear, org]);

    // Charger les données de l'event sélectionné
    useEffect(() => {
        const fetchEventData = async () => {
            if (selectedYear && org && eventName) {
                const docRef = doc(db, "fightData", "organisations", org, "events", selectedYear, eventName);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setEventData(data);
                } else {
                    console.log("No such document!");
                }
            }
        };

        fetchEventData();
    }, [selectedYear, eventName, org]);

    useEffect(() => { // RÉCUPÉRATION TIME 
        if (selectedCard && eventData) {
            if (selectedCard === 'early prelims') {
                setTime(eventData.earlyprelimstime)
            } else if (selectedCard === 'prelims') {
                setTime(eventData.prelimstime)
            } else if (selectedCard === 'main card') {
                setTime(eventData.maincardtime)
            }
        }
    }, [selectedCard, eventData]);


    const [lastName1, setLastName1] = useState("");
    const [lastName2, setLastName2] = useState("");
    useEffect(() => { // setCombatId
        if (lastName1 && lastName2) {
            setCombatId(`${lastName1} vs ${lastName2}`)
        }
    }, [lastName1, lastName2]);

    const [winner, setWinner] = useState("");
    const [loser, setLoser] = useState("");
    const [draw1, setDraw1] = useState("");
    const [draw2, setDraw2] = useState("");
    const [NC1, setNC1] = useState("");
    const [NC2, setNC2] = useState("");


    useEffect(() => { //  convertir win en nombre lors de la comparaison :
        if (parseInt(win, 10) === 1) {  // nom1 a gagné
            setWinner(nom1);
            setLoser(nom2);
        } else if (parseInt(win, 10) === 2) { // nom2 a gagné
            setWinner(nom2);
            setLoser(nom1);
        } else if (parseInt(win, 10) === 3) { // égalité
            setDraw1(nom1);
            setDraw2(nom2);
        } else if (parseInt(win, 10) === 4) { // no contest
            setNC1(nom1);
            setNC2(nom2);
        }
    }, [win, nom1, nom2]);

    const [formulaireVisible, setFormulaireVisible] = useState(0); // 1 = combat passé, 2 = combat à venir
    const [displayFighter, setDisplayFighter] = useState(0); // 1 = combat passé, 2 = combat à venir

    const [catchWeight, setCatchWeight] = useState(0);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const combatRef = doc(db, "fightData", "organisations", org, 'events', selectedYear, eventName, 'card', combatId);
            if (formulaireVisible === 2) { // COMBAT À VENIR
                handleUpdateDivisionFuturFight();
                await setDoc(combatRef, {
                    amateur: Boolean(amateur),
                    NC1: "",
                    NC2: "",
                    champ: Number(ifChamp),
                    card: selectedCard,
                    combatStatsId: "",
                    date: time,
                    catchWeight: Number(catchWeight),
                    division,
                    draw1: "",
                    draw2: "",
                    endTime: "",
                    eventName,
                    fighter1_predictions: 1,
                    fighter2_predictions: 1,
                    id: combatId,
                    loser: "",
                    main: Number(ifMain),
                    nom1,
                    nom2,
                    numberOfRound,
                    order,
                    orga: org,
                    round: 0,
                    state: 0,
                    stopTime: 0,
                    tournament: Number(ifTournament),
                    way: 0,
                    win: 0,
                    winner: "",

                });

                const predictionRef = doc(db, "fightData", "organisations", org, 'events', selectedYear, eventName, 'card', combatId, "stats", "predictionsDoc");
                await setDoc(predictionRef, {
                    fighter1_predictions: 1,
                    fighter2_predictions: 1,
                });

            } else if (formulaireVisible === 1) { // COMBAT PASSÉ
                await setDoc(combatRef, {
                    amateur: Boolean(amateur),
                    NC1,
                    NC2,
                    champ: Number(ifChamp),
                    card: selectedCard,
                    date: eventData.date,
                    catchWeight: Number(catchWeight),
                    division,
                    draw1,
                    draw2,
                    endTime,
                    eventName,
                    fighter1_predictions: 1,
                    fighter2_predictions: 1,
                    id: combatId,
                    loser,
                    main: Number(ifMain),
                    nom1,
                    nom2,
                    numberOfRound,
                    order,
                    orga: org,
                    round: Number(round),
                    state: 3,
                    stopTime: 0,
                    time,
                    tournament: Number(ifTournament),
                    way: Number(way),
                    win: Number(win),
                    winner,
                });

            }

            const statsRef = doc(db, "fightData", "organisations", org, 'events', selectedYear, eventName, 'card', combatId, "stats", "statsDoc");
            await setDoc(statsRef, {

                fighter1_signStrikes: 0,
                fighter1_totalStrikes: 0,
                fighter1_controlTime: '',
                fighter1_knockdowns: 0,
                fighter1_submissionTry: 0,
                fighter1_takedowns: 0,

                fighter2_signStrikes: 0,
                fighter2_totalStrikes: 0,
                fighter2_controlTime: '',
                fighter2_knockdowns: 0,
                fighter2_submissionTry: 0,
                fighter2_takedowns: 0,

            });

            if (selectedCard === 'prelims') {
                const eventRef = doc(db, "fightData", "organisations", org, 'events', selectedYear, eventName);
                await updateDoc(eventRef, { ifPrelims: true })
            }



            setCatchWeight(0); setIfTournament(0);
            setDivision(''); setCombatId(''); setIfMain(''); setIfChamp(''); setNom1(''); setNom2(''); setOrder(''); setNumberOfRound('');
            setWin(''); setWay(''); setEndTime(''); setRound('');
            alert("Combat soumis avec succès !");
        } catch (error) {
            console.error("Error adding document: ", error);
        }

    };

    const handleUpdateDivisionFuturFight = async () => {

        try {
            const docRef1 = doc(db, "fighterData", nom1);
            const docRef2 = doc(db, "fighterData", nom2);

            const docNom1 = await getDoc(docRef1);
            const docNom2 = await getDoc(docRef2);

            if (docNom1.exists()) {
                // si org ou division du combat n'est pas le même que dans le doc du fighter, modifier le doc fighter
                const data = docNom1.data();
                if (data.orga !== org || data.division !== division) {
                    if (division !== "catch") {
                        const updateData = {
                            orga: org,
                            division: division
                        };

                        if (data.orga !== org) {
                            updateData.fighterRank = {}; // Remet le champ fighterRank à vide
                        }

                        await updateDoc(docRef1, updateData);

                    } else if (data.orga !== org) { // si catchweight mais org différent
                        await updateDoc(docRef1, {
                            orga: org,
                            fighterRank: {} // Remet le champ fighterRank à vide
                        });
                    }
                }

                if (data.amateur === true && amateur === false) { // SI COMBATTANT AMATEUR MAIS PREMIER COMBAT PRO, METTRE COMBATTANT EN AMATEUR SUR FALSE (PRO)
                    await updateDoc(docRef1, {
                        amateur: false
                    });
                }
            }

            if (docNom2.exists()) {
                // si org ou division du combat n'est pas le même que dans le doc du fighter, modifier le doc fighter
                const data = docNom2.data();
                if (data.orga !== org || data.division !== division) {
                    if (division !== "catch") {
                        const updateData = {
                            orga: org,
                            division: division
                        };

                        if (data.orga !== org) {
                            updateData.fighterRank = {}; // Remet le champ fighterRank à vide
                        }

                        await updateDoc(docRef2, updateData);
                    } else if (data.orga !== org) { // si catchweight mais org différent
                        await updateDoc(docRef2, {
                            orga: org,
                            fighterRank: {} // Remet le champ fighterRank à vide
                        });
                    }
                }

                if (data.amateur === true && amateur === false) { // SI COMBATTANT AMATEUR MAIS PREMIER COMBAT PRO, METTRE COMBATTANT EN AMATEUR SUR FALSE (PRO)
                    await updateDoc(docRef2, {
                        amateur: false
                    });
                }
            }

        } catch (error) {
            console.error("Error: ", error);
        }
    };

    return (
        <div style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
        }}>
            <div style={{ // CARD
                marginTop: '100px', width: '100%', alignItems: 'center', justifyContent: 'center', backgroundColor: "#e3e3e3", display: 'flex', flexDirection: 'column',
            }}>
                <h2 style={{ color: '#ff3030', marginBottom: '20px', }}>{t("Update the card order")}</h2>
                <SeeCard />


            </div>

            <div style={{
                display: 'flex', flexDirection: 'row', alignItems: 'flex-start', width: '100%',
            }}>


                <div style={{ // DONNÉE FIGHTER
                    marginTop: '100px', width: '40%', alignItems: 'center', justifyContent: 'center', marginLeft: '40px'
                }}>

                    <ListFightersCombat
                        combattant1={nom1}
                        setCombattant1={setNom1}
                        combattant2={nom2}
                        setCombattant2={setNom2}
                        setLastName1={setLastName1}
                        setLastName2={setLastName2}
                        orgs={orgs} />

                    <div style={{ // BOUTONS
                        flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%',
                    }}>
                        <button // 
                            onClick={() => setDisplayFighter(1)}
                            style={{
                                padding: '10px 20px', border: 'none', backgroundColor: 'white', border: '2px solid #ff3030',
                                color: displayFighter === 1 ? 'white' : 'black', borderRadius: 50, marginTop: '10px', cursor: 'pointer',
                                outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                height: 50, width: '45%', fontSize: 15, backgroundColor: displayFighter === 1 ? '#ff3030' : 'white',

                            }}>
                            {t("Add fighter")}
                        </button>

                        <button // 
                            onClick={() => setDisplayFighter(2)}
                            style={{
                                padding: '10px 20px', border: 'none', backgroundColor: 'white', border: '2px solid #ff3030',
                                color: displayFighter === 2 ? 'white' : 'black', borderRadius: 50, marginTop: '10px', cursor: 'pointer',
                                outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                height: 50, width: '45%', fontSize: 15, backgroundColor: displayFighter === 2 ? '#ff3030' : 'white', marginLeft: 20
                            }}>
                            {t("Update fighter")}
                        </button>
                    </div>

                    {displayFighter === 1 ? <SubmitFighter /> : null}
                    {displayFighter === 2 ? <UpdateFighter /> : null}


                </div>

                <div style={{
                    display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', fontFamily: "'Arial', sans-serif", justifyContent: 'center',
                    borderRadius: '10px', width: '40%', margin: '50px auto',
                }}>

                    <h2 style={{ color: '#ff3030', marginBottom: '20px' }}>{t("Submit a fight")}</h2>

                    <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginBottom: 30 }}>
                        <button // RÉSULTAT
                            onClick={() => setFormulaireVisible(1)}
                            style={{
                                padding: '10px 20px', border: 'none', backgroundColor: 'white', border: '2px solid #ff3030',
                                color: formulaireVisible === 1 ? 'white' : 'black', borderRadius: 50, marginTop: '10px', cursor: 'pointer',
                                outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                height: 50, width: '45%', fontSize: 15, backgroundColor: formulaireVisible === 1 ? '#ff3030' : 'white', textTransform: 'uppercase'

                            }}>
                            {t("Past fight")}
                        </button>

                        <button // RÉSULTAT
                            onClick={() => setFormulaireVisible(2)}
                            style={{
                                padding: '10px 20px', border: 'none', backgroundColor: 'white', border: '2px solid #ff3030',
                                color: formulaireVisible === 2 ? 'white' : 'black', borderRadius: 50, marginTop: '10px', cursor: 'pointer',
                                outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder', textTransform: 'uppercase',
                                height: 50, width: '45%', fontSize: 15, backgroundColor: formulaireVisible === 2 ? '#ff3030' : 'white', marginLeft: 20
                            }}>
                            {t("Upcoming fight")}
                        </button>
                    </div>

                    {formulaireVisible === 1 || formulaireVisible === 2 ?
                        <form onSubmit={handleSubmit} style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                            <div style={{ // Organisation
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>Organization :</label>
                                <select
                                    value={org}
                                    onChange={(e) => { setOrg(e.target.value); }}
                                    required
                                    style={{
                                        width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none',
                                    }}
                                >
                                    <option value="">{t("Select an organization")}</option>
                                    {orgs.map((org, index) => (
                                        <option key={index} value={org}>
                                            {org}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div style={{ // YEARS
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>{t("Year")} :</label>
                                <select
                                    value={selectedYear}
                                    onChange={(e) => setSelectedYear(e.target.value)}
                                    required
                                    style={{
                                        width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none',
                                    }}
                                >
                                    <option value="">{t("Select a year")}</option>
                                    {years.map((years, index) => (
                                        <option key={index} value={years}>
                                            {years}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div style={{ // EVENT
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>Event :</label>
                                <select
                                    value={eventName}
                                    onChange={(e) => setEventName(e.target.value)}
                                    required
                                    style={{
                                        width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none',
                                    }}
                                >
                                    <option value="">{t("Select an event")}</option>
                                    {events.sort((a, b) => a.displayEventName?.localeCompare(b.displayEventName)).map((event, index) => (
                                        <option key={index} value={event.id}>
                                            {event.displayEventName}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div style={{ // CARD
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>Card :</label>
                                <select
                                    value={selectedCard}
                                    onChange={(e) => setSelectedCard(e.target.value)}
                                    required
                                    style={{
                                        width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none',
                                    }}
                                >
                                    <option value="">{t("Select a card")}</option>
                                    {cards.map((cards, index) => (
                                        <option key={index} value={cards}>
                                            {cards}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div style={{ // AMATEUR
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label style={{ marginRight: 10 }}>{t('Amateur')} :</label>

                                <select
                                    value={amateur}
                                    onChange={(e) => setAmateur(e.target.value === 'true')}
                                    required
                                    style={{
                                        width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none',
                                    }}
                                >
                                    <option value="">{t("Select an answer")}</option>
                                    {amateurs.map((amateur, index) => (
                                        <option key={index} value={amateur.value}>
                                            {amateur.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div style={{ // division
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>{t("Division")} :</label>
                                <label style={{ color: 'red' }}>{t("Warning if Catchweight: First search for fighters, then select the catchweight category.")}</label>
                                <select
                                    value={division}
                                    onChange={(e) => setDivision(e.target.value)}
                                    required
                                    style={{
                                        width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none',
                                    }}
                                >
                                    <option value="">{t("Select a division")}</option>
                                    {divisions.map((division, index) => (
                                        <option key={index} value={division.value}>
                                            {division.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {division === 'catch' ?
                                <div style={{ // IF CATCH WEIGHT
                                    width: '100%', marginBottom: '10px'
                                }}>

                                    <label>IF CATCHWEIGHT :</label>

                                    <div style={{
                                        width: '100%', marginBottom: '10px'
                                    }}>
                                        <input
                                            type="text"
                                            value={catchWeight}
                                            onChange={(e) => setCatchWeight(e.target.value)}
                                            required
                                            style={{
                                                width: '100%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                                borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                            }}
                                        />
                                    </div>
                                </div>
                                : null}

                            <div style={{ // NOM COMBATTANT 1
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>NAME FIGHTER 1 :</label>

                                <input
                                    type="text"
                                    value={search1}
                                    onChange={(e) => setSearch1(e.target.value)}
                                    placeholder={nom1 ? nom1 : "Search a fighter..."}

                                    style={{
                                        width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none',
                                    }}
                                />



                                {search1 && (
                                    <div style={{ width: '90%', border: '1.5px solid #ff3030', borderRadius: '5px', maxHeight: '150px', overflowY: 'scroll' }}>
                                        {filteredFighters1.map((fighter, index) => (
                                            <div
                                                key={index}
                                                style={{ padding: '10px', cursor: 'pointer' }}
                                                onClick={() => {
                                                    setNom1(fighter.id)
                                                    setLastName1(fighter.lastName)
                                                    setSearch1('');  // optionnel: vider le champ de recherche lors de la sélection
                                                }}>
                                                {fighter.id}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>

                            <div style={{ // NOM COMBATTANT 2
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>NAME FIGHTER 2 :</label>
                                <input
                                    type="text"
                                    value={search2}
                                    onChange={(e) => setSearch2(e.target.value)}
                                    placeholder={nom2 ? nom2 : "Search a fighter..."}

                                    style={{
                                        width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none',
                                    }}
                                />

                                {search2 && (
                                    <div style={{ width: '90%', border: '1.5px solid #ff3030', borderRadius: '5px', maxHeight: '150px', overflowY: 'scroll' }}>
                                        {filteredFighters2.map((fighter, index) => (
                                            <div
                                                key={index}
                                                style={{ padding: '10px', cursor: 'pointer' }}
                                                onClick={() => {
                                                    setNom2(fighter.id)
                                                    setLastName2(fighter.lastName)
                                                    setSearch2('');  // optionnel: vider le champ de recherche lors de la sélection
                                                }}
                                            >
                                                {fighter.id}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>

                            <div style={{ // COMBAT ID
                                width: '100%', marginBottom: '10px'
                            }}>

                                <label>Name of the fight (name vs name ( + number if they already face each other)) :</label>

                                <div style={{
                                    width: '100%', marginBottom: '10px'
                                }}>
                                    <input
                                        type="text"
                                        value={combatId}
                                        onChange={(e) => setCombatId(e.target.value)}
                                        required
                                        style={{
                                            width: '100%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                            borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                        }}
                                    />
                                </div>
                            </div>

                            {formulaireVisible === 1 ?
                                <div style={{ // VAINQUEUR
                                    width: '100%', marginBottom: '10px'
                                }}>
                                    <label>WINNER :</label>
                                    <select
                                        value={win}
                                        onChange={(e) => setWin(e.target.value)}
                                        required
                                        style={{
                                            width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                            borderRadius: '5px', outline: 'none',
                                        }}
                                    >
                                        <option value="">{t("Select a winner")}:</option>
                                        {winArray.map((winArray, index) => (
                                            <option key={index} value={winArray.value}>
                                                {winArray.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                : null}

                            {formulaireVisible === 1 ?
                                <div style={{ // WAY
                                    width: '100%', marginBottom: '10px'
                                }}>
                                    <label>{t("RESULTS")} :</label>
                                    <select
                                        value={way}
                                        onChange={(e) => setWay(e.target.value)}
                                        required
                                        style={{
                                            width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                            borderRadius: '5px', outline: 'none',
                                        }}
                                    >
                                        <option value="">{t("Select a result")}:</option>
                                        {ways.map((ways, index) => (
                                            <option key={index} value={ways.value}>
                                                {ways.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                : null}

                            {formulaireVisible === 1 ?
                                <div style={{ // ROUND
                                    width: '100%', marginBottom: '10px'
                                }}>
                                    <label>{t("In the round N°")}:</label>
                                    <select
                                        value={round}
                                        onChange={(e) => setRound(e.target.value)}
                                        required
                                        style={{
                                            width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                            borderRadius: '5px', outline: 'none',
                                        }}
                                    >
                                        <option value="">{("Select a round")}:</option>
                                        {rounds.map((rounds, index) => (
                                            <option key={index} value={rounds.value}>
                                                {rounds.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                : null}

                            {formulaireVisible === 1 ?
                                <div style={{ // END TIME
                                    width: '100%', marginBottom: '10px'
                                }}>

                                    <label>END TIMER:</label>
                                    <label style={{ color: '#565656', fontSize: 15 }}> (ex: 2:34)</label>
                                    <div style={{
                                        width: '100%', marginBottom: '10px'
                                    }}>
                                        <input
                                            type="text"
                                            value={endTime}
                                            onChange={(e) => setEndTime(e.target.value)}
                                            required
                                            style={{
                                                width: '20%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                                borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                            }}
                                        />
                                    </div>
                                </div>
                                : null}

                            <div style={{ // CHAMP
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>Title fight ? :</label>
                                <select
                                    value={ifChamp}
                                    onChange={(e) => setIfChamp(e.target.value)}
                                    required
                                    style={{
                                        width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none',
                                    }}
                                >
                                    <option value="">{t("Select a fight type")}</option>
                                    {champ.map((champ, index) => (
                                        <option key={index} value={champ.value}>
                                            {champ.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div style={{ // MAIN EVENT
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>MAIN EVENT ? :</label>
                                <select
                                    value={ifMain}
                                    onChange={(e) => setIfMain(e.target.value)}
                                    required
                                    style={{
                                        width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none',
                                    }}
                                >
                                    <option value="">{t("Select a fight type")}</option>
                                    {main.map((main, index) => (
                                        <option key={index} value={main.value}>
                                            {main.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div style={{ // TOURNOI
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>TOURNAMENT ? :</label>
                                <select
                                    value={ifTournament}
                                    onChange={(e) => setIfTournament(e.target.value)}
                                    required
                                    style={{
                                        width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none',
                                    }}
                                >
                                    <option value="">{t("Select a tournament phase")}:</option>
                                    {tournament.map((tournament, index) => (
                                        <option key={index} value={tournament.value}>
                                            {tournament.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div style={{ // NOMBRE DE ROUND
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>{t("HOW MANY ROUNDS")} :</label>

                                <select
                                    value={numberOfRound}
                                    onChange={(e) => setNumberOfRound(e.target.value)}
                                    required
                                    style={{
                                        width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none',
                                    }}
                                >
                                    <option value="">{t("Select a number of round")}:</option>
                                    {roundTotal.map((roundTotal, index) => (
                                        <option key={index} value={roundTotal.value}>
                                            {roundTotal.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div style={{ // ORDRE DANS LA CARTE
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>{t("ORDER IN THE CARD")} :</label>
                                <select
                                    value={order}
                                    onChange={(e) => setOrder(e.target.value)}
                                    required
                                    style={{
                                        width: '100%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none',
                                    }}
                                >
                                    <option value="">{t("Select an order")}</option>
                                    {orders.map((orders, index) => (
                                        <option key={index} value={orders.value}>
                                            {orders.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <button
                                type="submit"
                                style={{
                                    padding: '10px 20px', border: 'none', backgroundColor: '#ff3030',
                                    color: 'white', borderRadius: '25px', marginTop: '10px', cursor: 'pointer',
                                    outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder'
                                }}
                            >
                                {t("Submit")}
                            </button>
                        </form>
                        : null}
                </div>
            </div>
        </div>

    );
}

export function UpdateCombat() {
    const { t } = useTranslation();
    const [events, setEvents] = useState([]);
    const [eventName, setEventName] = useState("");
    const [eventData, setEventData] = useState([]);
    const [combatId, setCombatId] = useState("");
    const [nom1, setNom1] = useState("");
    const [nom2, setNom2] = useState("");
    const [country, setCountry] = useState([]);
    const [numberOfRound, setNumberOfRound] = useState("");
    const [org, setOrg] = useState("");
    const years = ['2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030'];
    const currentYear = new Date().getFullYear();
    const [selectedYear, setSelectedYear] = useState(currentYear.toString());
    const [orgs, setOrgs] = useState([]);
    const cards = eventData.ifEarlyPrelims ? ['main card', 'prelims', 'early prelims'] : ['main card', 'prelims'];
    const [selectedCard, setSelectedCard] = useState('');
    const [time, setTime] = useState('');
    const [combatStatsId, setCombatStatsId] = useState('');
    const [stopTime, setStopTime] = useState(0);

    useEffect(() => { // RÉCUPÉRATION ORGA
        const fetchOrgs = async () => {

            const orgsDocRef = doc(db, "fightData", "organisations");

            try {
                const orgsDocSnap = await getDoc(orgsDocRef);

                if (orgsDocSnap.exists()) {
                    const { listOrgasNames } = orgsDocSnap.data(); // Assurez-vous que 'listOrgasNames' est correctement initialisé dans votre document
                    setOrgs(listOrgasNames);
                } else {
                    console.log("Document 'organisations' non trouvé.");
                }
            } catch (error) {
                console.error("Erreur lors de la récupération du document:", error);
            }
        };

        fetchOrgs();
    }, []);

    const champ = [{ value: 0, label: "NORMAL" }, { value: 1, label: 'CHAMP' }, { value: 2, label: 'INTERIM CHAMP' }];
    const [ifChamp, setIfChamp] = useState(0);

    const main = [{ value: 0, label: "NORMAL" }, { value: 1, label: 'MAIN EVENT' }, { value: 2, label: 'CO MAIN EVENT' }];
    const [ifMain, setIfMain] = useState(0);


    const tournament = [{ value: 0, label: "aucun" }, { value: 1, label: 'FINAL' }, { value: 2, label: 'SEMI-FINAL' }, { value: 3, label: 'QUARTER-FINAL' }];
    const [ifTournament, setIfTournament] = useState(0);

    const rounds = [{ value: 1, label: 'ROUND 1' }, { value: 2, label: 'ROUND 2' }, { value: 3, label: 'ROUND 3' }, { value: 4, label: 'ROUND 4' }, { value: 5, label: 'ROUND 5' }];

    const orders = [{ value: "first", label: `first fight ${selectedCard}` }, { value: "1", label: `last fight ${selectedCard}` },
    { value: "2", label: `Second last combat ${selectedCard}` }, { value: "3", label: "3rd fight from the end" },
    { value: "4", label: "4th fight from the end" }, { value: "5", label: "5th fight from the end" },
    { value: "6", label: "6th fight from the end" }, { value: "7", label: "7th fight from the end" },
    { value: "8", label: "8th fight from the end" }, { value: "9", label: "9th fight from the end" },
    { value: "10", label: "10th fight from the end" }, { value: "11", label: "11th fight from the end" },
    { value: "12", label: "12th fight from the end" }, { value: "13", label: "13th fight from the end" },
    { value: "14", label: "14th fight from the end" }, { value: "15", label: "15th fight from the end" }];
    const [order, setOrder] = useState("");

    const divisions = [{ value: "1heavy", label: "HEAVYWEIGHT" },
    { value: "2lightheavy", label: "LIGHT-HEAVYWEIGHT" },
    { value: "3middle", label: "MIDDLEWEIGHT" },
    { value: "4welter", label: "WELTERWEIGHT" },
    { value: "5light", label: "LIGHTWEIGHT" },
    { value: "6feather", label: "FEATHERWEIGHT" },
    { value: "7bantam", label: "BANTAMWEIGHT" },
    { value: "8fly", label: "FLYWEIGHT" },
    { value: "9straw", label: "STRAWWEIGHT" },
    { value: "10atom", label: "ATOMWEIGHT" },
    { value: "catch", label: "CATCH WEIGHT" }];

    const [amateur, setAmateur] = useState(false);
    const amateurs = [{ value: false, label: "NO" }, { value: true, label: "YES" }];

    const ways = [{ value: 0, label: t("None") }, { value: 1, label: "KO/TKO" }, { value: 2, label: "Submission" }, { value: 3, label: "Decision" },
    { value: 3.1, label: "Unanimous Decision" }, { value: 3.2, label: "Split Décision" }, { value: 3.3, label: "Majority Decision" },
    { value: 4, label: "Draw" }, { value: 4.1, label: "Unanimous Draw" }, { value: 4.2, label: "Split Draw" }, { value: 4.3, label: "Majority Draw" },
    { value: 5, label: "NO CONTEST" }, { value: 6, label: "Doctor Stoppage" }, { value: 7, label: "DISQUALIFICATION" }, { value: 8, label: "Forfeit" },
    { value: 9, label: "Technical draw" }, { value: 10, label: "Technical decision" }];

    //////// AFFICHE COMBAT COMME DANS APP
    const [userLanguage, setUserLanguage] = useState('en');


    const toggleLanguage = () => {
        setUserLanguage(prevLanguage => (prevLanguage === 'en' ? 'fr' : 'en'));
    };
    ////// DIVISION EN POIDS

    const divisionWeights = {
        '1heavy': 265,
        '2lightheavy': 205,
        '3middle': 185,
        '4welter': 170,
        '5light': 155,
        '6feather': 145,
        '7bantam': 135,
        '8fly': 125,
        '9straw': 115,
        '10atom': 105.8,
    };

    const divisionNamesMen = {
        '1heavy': t('Heavyweight'),
        '2lightheavy': t('Light-heavyweight'),
        '3middle': t('Middleweight'),
        '4welter': t('Welterweight'),
        '5light': t('Lightweight'),
        '6feather': t('Featherweight'),
        '7bantam': t('Bantamweight'),
        '8fly': t('Flyweight'),
        '9straw': t("Strawweight"),
        '10atom': t("Atomweight"),
        'catch': t('Catchweight')
    };

    const wins = [{ value: 0, label: "Aucun" }, { value: 1, label: `${nom1}` }, { value: 2, label: `${nom2}` }, { value: 3, label: 'ÉGALITÉ' }, { value: 4, label: 'NO-CONTEST' }];
    const [win, setWin] = useState("");
    const states = [{ value: 0, label: "initial state" }, { value: 1, label: 'imminent start' }, { value: 1.5, label: 'Walkout' }, { value: 2, label: 'fight in progress' }, , { value: 3, label: 'Over' }];
    const [division, setDivision] = useState("");
    const [catchWeight, setCatchWeight] = useState(0);
    const [combatData, setCombatData] = useState([]);

    const [NC1, setNC1] = useState("");
    const [NC2, setNC2] = useState("");
    const [draw1, setDraw1] = useState("");
    const [draw2, setDraw2] = useState("");
    const [endTime, setEndTime] = useState("");
    const [loser, setLoser] = useState("");
    const [state, setState] = useState(0);
    const [winner, setWinner] = useState("");
    const [round, setRound] = useState(0);
    const [way, setWay] = useState("");

    useEffect(() => { // récupération de données
        setAmateur(combatData ? combatData.amateur : false);
        setNC1(combatData ? combatData.NC1 : null);
        setNC2(combatData ? combatData.NC2 : null);
        setIfChamp(combatData ? combatData.champ : null);
        setIfTournament(combatData ? combatData.tournament : null);
        setCatchWeight(combatData ? combatData.catchWeight : null);
        setCountry(combatData && combatData.country ? combatData.country : []);
        setDivision(combatData ? combatData.division : null);
        setDraw1(combatData ? combatData.draw1 : null);
        setDraw2(combatData ? combatData.draw2 : null);
        setEndTime(combatData ? combatData.endTime : null);
        setCombatId(combatData ? combatData.id : null);
        setSelectedCard(combatData ? combatData.card : null);
        setLoser(combatData ? combatData.loser : null);
        setIfMain(combatData ? combatData.main : null);
        setNom1(combatData ? combatData.nom1 : null);
        setNom2(combatData ? combatData.nom2 : null);
        setNumberOfRound(combatData ? combatData.numberOfRound : null);
        setOrder(combatData ? combatData.order : null);
        setRound(combatData ? combatData.round : null);
        setState(combatData ? combatData.state : null);
        setStopTime(combatData ? combatData.stopTime : null);
        setWay(combatData ? combatData.way : null);
        setWin(combatData ? combatData.win : null);
        setTime(combatData ? combatData.time : null);
        setWinner(combatData ? combatData.winner : null);
        setCombatStatsId(combatData && combatData.combatStatsId ? combatData.combatStatsId : '');

    }, [combatData]);

    const navigate = useNavigate();

    ////////////// NOM COMBATTANTS //////////////
    function useDebounce(value, delay) { // DÉLAI POUR RECHERCHE
        const [debouncedValue, setDebouncedValue] = useState(value);

        useEffect(() => {
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            return () => {
                clearTimeout(handler);
            };
        }, [value, delay]);

        return debouncedValue;
    }

    const [search1, setSearch1] = useState('');
    const [fighters1, setFighters1] = useState([]);
    const [filteredFighters1, setFilteredFighters1] = useState([]);

    const [search2, setSearch2] = useState('');
    const [fighters2, setFighters2] = useState([]);
    const [filteredFighters2, setFilteredFighters2] = useState([]);

    const debouncedSearchTerm = useDebounce(search1, 500);
    const debouncedSearchTerm2 = useDebounce(search2, 500);  // Attend 500ms après la dernière frappe
    // Créez une instance de l'index Algolia

    const fetchFighters1 = async (search1, attributesToRetrieve = ['id']) => {
        const functions = getFunctions(app);
        const searchFighters = httpsCallable(functions, 'searchFighters');

        let fetchedData = [];

        try {
            // Créez une requête de recherche Algolia sans filtres
            const result = await searchFighters({
                search: search1,
                attributesToRetrieve
            });

            // Ajoutez tous les résultats de recherche à fetchedData
            if (result.data && Array.isArray(result.data.hits)) {
                fetchedData = result.data.hits.map(hit => ({
                    id: hit.id,
                    ...hit
                }));
            } else {
                console.log('No results from Algolia search or data structure is unexpected.');
            }
        } catch (error) {
            console.error('Error loading favorite fighters data:', error);
        }


        return fetchedData;
    };

    const fetchFighters2 = async (search2, attributesToRetrieve = ['id']) => {
        const functions = getFunctions(app);
        const searchFighters = httpsCallable(functions, 'searchFighters');

        let fetchedData = [];

        try {
            // Créez une requête de recherche Algolia sans filtres
            const result = await searchFighters({
                search: search2,
                attributesToRetrieve
            });

            // Ajoutez tous les résultats de recherche à fetchedData
            if (result.data && Array.isArray(result.data.hits)) {
                fetchedData = result.data.hits.map(hit => ({
                    id: hit.id,
                    ...hit
                }));
            } else {
                console.log('No results from Algolia search or data structure is unexpected.');
            }
        } catch (error) {
            console.error('Error loading favorite fighters data:', error);
        }


        return fetchedData;
    };

    ////////////// NOM1
    useEffect(() => {
        if (debouncedSearchTerm) {
            const fetchFightersData = async () => {
                const fetchedData = await fetchFighters1(debouncedSearchTerm);
                setFighters1(fetchedData);
                setFilteredFighters1(fetchedData);
            };
            fetchFightersData();
        }
    }, [debouncedSearchTerm]);

    useEffect(() => {
        const results = fighters1.filter(fighter => fighter.id && fighter.id.toLowerCase().includes(search1.toLowerCase()));
        setFilteredFighters1(results);
    }, [search1]);



    ////////////// NOM2
    useEffect(() => {
        if (debouncedSearchTerm2) {
            const fetchFightersData = async () => {
                const fetchedData = await fetchFighters2(debouncedSearchTerm2);
                setFighters2(fetchedData);
                setFilteredFighters2(fetchedData);
            };
            fetchFightersData();
        }
    }, [debouncedSearchTerm2]);

    useEffect(() => {
        const results = fighters2.filter(fighter => fighter.id && fighter.id.toLowerCase().includes(search2.toLowerCase()));
        setFilteredFighters2(results);
    }, [search2]);
    ///

    /////////////////////////

    // Charger tous les events

    useEffect(() => { // RÉCUPÉRATION EVENTS
        const fetchEvents = async () => {
            if (selectedYear && org) {
                const querySnapshot = await getDocs(collection(db, "fightData", "organisations", org, "events", selectedYear));
                const events = [];
                querySnapshot.forEach((document) => {
                    const data = document.data();
                    events.push({
                        id: document.id,               // Document ID
                        displayEventName: data.displayEventName || document.id  // Fallback to ID if displayEventName doesn't exist
                    });
                });
                setEvents(events);
            };
        };
        fetchEvents();
    }, [selectedYear, org]);

    // Charger les données de l'event sélectionné
    useEffect(() => {
        const fetchEventData = async () => {
            if (selectedYear && org && eventName) {
                const docRef = doc(db, "fightData", "organisations", org, "events", selectedYear, eventName);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setEventData(data);
                } else {
                    console.log("No such document!");
                }
            }
        };

        fetchEventData();
    }, [selectedYear, eventName, org]);

    ///////////////// RÉCUPÉRATION DES COMBATS
    const [combats, setCombats] = useState([]);
    const [selectedCombat, setSelectedCombat] = useState('');
    const [formulaireVisible, setFormulaireVisible] = useState(false);

    useEffect(() => { // RÉCUPÉRATION TIME 
        if (selectedCard && eventData) {
            if (selectedCard === 'early prelims') {
                setTime(eventData.earlyprelimstime)
            } else if (selectedCard === 'prelims') {
                setTime(eventData.prelimstime)
            } else if (selectedCard === 'main card') {
                setTime(eventData.maincardtime)
            }
        }
    }, [selectedCard, eventData, combatData]);

    useEffect(() => {

        const fetchCombats = async () => {
            if (selectedYear && org && eventName) {

                const combatsCollection = collection(db, "fightData", "organisations", org, "events", selectedYear, eventName, 'card');
                const q = query(combatsCollection, where('card', '!=', 'cancelled'));

                const querySnapshot = await getDocs(q);

                const combatsData = [];
                querySnapshot.forEach((document) => {
                    combatsData.push({ id: document.id, order: document.data().order, card: document.data().card });
                });

                // Séparer les éléments en numériques et non-numériques
                const numericCombats = combatsData.filter(combat => isNumeric(combat.order));
                const nonNumericCombats = combatsData.filter(combat => !isNumeric(combat.order));

                // Trier les éléments numériques en ordre décroissant
                numericCombats.sort((a, b) => {
                    if (b.card === 'main card' && a.card === 'prelims') {
                        return 1;
                    }
                    if (a.card === 'main card' && b.card === 'prelims') {
                        return -1;
                    }

                    if (b.card === 'main card' && a.card === 'early prelims') {
                        return 1;
                    }
                    if (a.card === 'main card' && b.card === 'early prelims') {
                        return -1;
                    }

                    if (b.card === 'prelims' && a.card === 'early prelims') {
                        return 1;
                    }
                    if (a.card === 'prelims' && b.card === 'early prelims') {
                        return -1;
                    }

                    const orderA = parseInt(a.order, 10);
                    const orderB = parseInt(b.order, 10);
                    return orderA - orderB;
                });

                // Fusionner les éléments numériques et non-numériques
                const mergedCombats = combatsData.map(combat => {
                    return {
                        ...combat,
                        orderNumeric: isNumeric(combat.order) ? parseInt(combat.order, 10) : null
                    };
                });

                // Trier les éléments fusionnés
                mergedCombats.sort((a, b) => {
                    if (b.card === 'main card' && a.card === 'prelims') {
                        return 1;
                    }
                    if (a.card === 'main card' && b.card === 'prelims') {
                        return -1;
                    }

                    if (b.card === 'main card' && a.card === 'early prelims') {
                        return 1;
                    }
                    if (a.card === 'main card' && b.card === 'early prelims') {
                        return -1;
                    }

                    if (b.card === 'prelims' && a.card === 'early prelims') {
                        return 1;
                    }
                    if (a.card === 'prelims' && b.card === 'early prelims') {
                        return -1;
                    }

                    if (a.orderNumeric !== null && b.orderNumeric !== null) {
                        return a.orderNumeric - b.orderNumeric;
                    } else if (a.orderNumeric !== null) {
                        return -1;
                    } else if (b.orderNumeric !== null) {
                        return 1;
                    } else {
                        if (a.order > b.order) {
                            return -1;
                        }
                        if (a.order < b.order) {
                            return 1;
                        }
                        return 0;
                    }
                });

                // Extraire les IDs des combats pour les utiliser dans le menu déroulant
                const combats = mergedCombats.map(combat => combat.id);




                setCombats(combats);
            };
        };
        fetchCombats();

    }, [selectedYear, org, eventName]);

    function isNumeric(value) {
        return /^-?\d+$/.test(value);
    }

    // Charger les données du combat sélectionné
    useEffect(() => {
        const fetchCombatData = async () => {
            if (selectedYear && org && eventName && selectedCombat) {
                const docRef = doc(db, "fightData", "organisations", org, "events", selectedYear, eventName, 'card', selectedCombat);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setCombatData(data);
                } else {
                    console.log("No such document!");
                }
            }
        };

        fetchCombatData();
    }, [selectedYear, eventName, org, selectedCombat]);


    const [dataReady, setDataReady] = useState(false);
    const [combattant1Data, setCombattant1Data] = useState([]);
    const [combattant2Data, setCombattant2Data] = useState([]);

    useEffect(() => { // RÉCUPÉRER LES DONNÉES DES COMBATTANTS
        const fetchCombattantsData = async () => {


            const [combattant1, combattant2] = await Promise.all([
                findCombattant(combatData.nom1, combatData.division, combatData.orga),
                findCombattant(combatData.nom2, combatData.division, combatData.orga),
            ]);


            setCombattant1Data(combattant1)
            setCombattant2Data(combattant2)
            setDataReady(true);
        };

        if (org && combatData) {
            fetchCombattantsData();
        }
    }, [combatData]);


    // WINNER/LOSER/DRAW/NC
    useEffect(() => { //  convertir win en nombre lors de la comparaison :
        if (parseInt(win, 10) === 1) {  // nom1 a gagné
            setWinner(nom1);
            setLoser(nom2);
        } else if (parseInt(win, 10) === 2) { // nom2 a gagné
            setWinner(nom2);
            setLoser(nom1);
        } else if (parseInt(win, 10) === 3) { // égalité
            setDraw1(nom1);
            setDraw2(nom2);
        } else if (parseInt(win, 10) === 4) { // no contest
            setNC1(nom1);
            setNC2(nom2);
        }
    }, [win, nom1, nom2]);

    const [fightIsPassed, setFightIsPassed] = useState(0); // 1 = combat passé, 2 = combat à venir


    const switchNames = () => {
        const temp = nom1;
        console.log('Nom1 avant:', nom1);
        console.log('Nom2 avant:', nom2);
        setNom1(nom2);
        setNom2(temp);
    };

    useEffect(() => {
        console.log('Nom1 updated:', nom1);
        console.log('Nom2 updated:', nom2);
    }, [nom1, nom2]);

    ///////// COUNTRY
    // Supprimer ou ajouter des pays à la liste countries
    // Fonction pour ajouter un nouveau pays
    const addCountry = (newCountry) => {
        if (newCountry && !country?.includes(newCountry.trim())) {
            setCountry([...country, newCountry.trim()]);
        }
    };

    // Fonction pour supprimer un pays
    const removeCountry = (countryToRemove) => {
        setCountry(country?.filter((country) => country !== countryToRemove));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (fightIsPassed === 2) { // IF FUTUR FIGHt, WE UPDATE THE FIGHTER's DATA
                handleUpdateDivisionFuturFight();
            }

            const articleRef = doc(db, "fightData", "organisations", org, 'events', selectedYear, eventName, 'card', combatId);
            await updateDoc(articleRef, {
                amateur: Boolean(amateur),
                NC1,
                NC2,
                card: selectedCard,
                champ: Number(ifChamp),
                combatStatsId: combatStatsId ? combatStatsId : null,
                country: country.length > 0 ? country : null,
                date: time,
                catchWeight: Number(catchWeight),
                division,
                draw1,
                draw2,
                endTime,
                eventName,
                id: combatId,
                loser,
                main: Number(ifMain),
                nom1,
                nom2,
                numberOfRound,
                order,
                orga: org,
                round: Number(round),
                state: Number(state),
                stopTime,
                tournament: ifTournament ? Number(ifTournament) : 0,
                way: Number(way),
                win: Number(win),
                winner,

            });

            if (selectedCard === 'prelims') {
                const eventRef = doc(db, "fightData", "organisations", org, 'events', selectedYear, eventName);
                await updateDoc(eventRef, { ifPrelims: true })
            }

            setCatchWeight(0); setIfTournament(0); setAmateur(""); setCombatStatsId("");
            setDivision(''); setCombatId(''); setIfMain(''); setIfChamp(''); setNom1(''); setNom2(''); setOrder(''); setTime(''); setNumberOfRound(''); setSelectedCombat('');
            setNC1(''); setNC2(''); setDraw1(''); setDraw2(''); setEndTime(''); setLoser(''); setNom1(''); setNom2(''); setRound(''); setSelectedCard('');
            setState(''); setWay(''); setWin(''); setWinner(''); setFormulaireVisible(false);
            alert("Combat soumis avec succès !");
        } catch (error) {
            console.error("Error adding document: ", error);
        }

    };


    const handleUpdateDivisionFuturFight = async () => {

        try {

            const docRef1 = doc(db, "fighterData", nom1);
            const docRef2 = doc(db, "fighterData", nom2);

            const docNom1 = await getDoc(docRef1);
            const docNom2 = await getDoc(docRef2);

            if (docNom1.exists()) {
                // si org ou division du combat n'est pas le même que dans le doc du fighter, modifier le doc fighter
                const data = docNom1.data();
                if (data.orga !== org || data.division !== division) {
                    if (division !== "catch") {
                        await updateDoc(docRef1, {
                            orga: org,
                            division: division
                        });
                    } else if (data.orga !== org) {
                        await updateDoc(docRef1, {
                            orga: org,
                        });
                    }
                }

                if (data.amateur === true && amateur === false) { // SI COMBATTANT AMATEUR MAIS PREMIER COMBAT PRO, METTRE COMBATTANT EN AMATEUR SUR FALSE (PRO)
                    await updateDoc(docRef1, {
                        amateur: false
                    });
                }
            }

            if (docNom2.exists()) {
                // si org ou division du combat n'est pas le même que dans le doc du fighter, modifier le doc fighter
                const data = docNom2.data();
                if (data.orga !== org || data.division !== division) {
                    if (division !== "catch") {
                        await updateDoc(docRef2, {
                            orga: org,
                            division: division
                        });
                    } else if (data.orga !== org) {
                        await updateDoc(docRef2, {
                            orga: org,
                        });
                    }
                }

                if (data.amateur === true && amateur === false) { // SI COMBATTANT AMATEUR MAIS PREMIER COMBAT PRO, METTRE COMBATTANT EN AMATEUR SUR FALSE (PRO)
                    await updateDoc(docRef2, {
                        amateur: false
                    });
                }
            }

        } catch (error) {
            console.error("Error: ", error);
        }
    };

    const handleDelete = async () => {
        if (window.confirm("Are you sure you want to delete this fight ?")) {
            try {
                const articleRef = doc(db, "fightData", "organisations", org, 'events', selectedYear, eventName, 'card', combatId);
                await deleteDoc(articleRef);

                setCatchWeight(0); setIfTournament(0); setCombatStatsId("");
                setDivision(''); setCombatId(''); setIfMain(''); setIfChamp(''); setNom1(''); setNom2(''); setOrder(''); setTime(''); setNumberOfRound(''); setSelectedCombat('');
                setNC1(''); setNC2(''); setDraw1(''); setDraw2(''); setEndTime(''); setLoser(''); setNom1(''); setNom2(''); setRound('');
                setState(''); setWay(''); setWin(''); setWinner(''); setSelectedCard('');
                alert("Document supprimé avec succès !");
                // Réinitialisez toutes les valeurs et cachez le formulaire si nécessaire
            } catch (error) {
                console.error("Error deleting document: ", error);
            }
        }
    };

    const handleCancel = async () => {
        if (window.confirm("Are you sure you want to cancel this fight ?")) {
            try {
                const fightRef = doc(db, "fightData", "organisations", org, 'events', selectedYear, eventName, 'card', combatId);
                await updateDoc(fightRef, {
                    card: 'cancelled',
                    state: 0,
                    main: 0
                });

                setCatchWeight(0); setIfTournament(0); setCombatStatsId("");
                setDivision(''); setCombatId(''); setIfMain(''); setIfChamp(''); setNom1(''); setNom2(''); setOrder(''); setTime(''); setNumberOfRound(''); setSelectedCombat('');
                setNC1(''); setNC2(''); setDraw1(''); setDraw2(''); setEndTime(''); setLoser(''); setNom1(''); setNom2(''); setRound('');
                setState(''); setWay(''); setWin(''); setWinner(''); setSelectedCard('');
                alert("Document cancelled avec succès !");
                // Réinitialisez toutes les valeurs et cachez le formulaire si nécessaire
            } catch (error) {
                console.error("Error deleting document: ", error);
            }
        }
    };

    return (
        <div style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
        }}>
            <div style={{ // CARD
                marginTop: '100px', width: '100%', alignItems: 'center', justifyContent: 'center', backgroundColor: "#e3e3e3", display: 'flex', flexDirection: 'column',
            }}>
                <h2 style={{ color: '#ff3030', marginBottom: '20px', }}>{t("Update the card order")}</h2>
                <SeeCard />


            </div>
            <div style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', fontFamily: "'Arial', sans-serif", justifyContent: 'center',
                borderRadius: '10px', width: '400px', margin: '50px auto',
            }}>

                <h2 style={{ color: '#ff3030', marginBottom: '20px' }}>{t("Update a fight")}</h2>


                <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginBottom: 30 }}>
                    <button // RÉSULTAT
                        onClick={() => setFightIsPassed(1)}
                        style={{
                            padding: '10px 20px', border: 'none', backgroundColor: 'white', border: '2px solid #ff3030',
                            color: fightIsPassed === 1 ? 'white' : 'black', borderRadius: 50, marginTop: '10px', cursor: 'pointer',
                            outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                            height: 50, width: '45%', fontSize: 15, backgroundColor: fightIsPassed === 1 ? '#ff3030' : 'white',

                        }}>
                        {t("Past fight")}
                    </button>

                    <button // RÉSULTAT
                        onClick={() => setFightIsPassed(2)}
                        style={{
                            padding: '10px 20px', border: 'none', backgroundColor: 'white', border: '2px solid #ff3030',
                            color: fightIsPassed === 2 ? 'white' : 'black', borderRadius: 50, marginTop: '10px', cursor: 'pointer',
                            outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                            height: 50, width: '45%', fontSize: 15, backgroundColor: fightIsPassed === 2 ? '#ff3030' : 'white', marginLeft: 20
                        }}>
                        {t("Upcoming fight")}
                    </button>
                </div>



                <div style={{ backgroundColor: '#ff3030', padding: '20px', borderRadius: 10 }}>

                    <div style={{ // Organisation
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label>Organization :</label>
                        <select
                            value={org}
                            onChange={(e) => setOrg(e.target.value)}
                            required
                            style={{
                                width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                borderRadius: '5px', outline: 'none',
                            }}
                        >
                            <option value="">{t("Select an organization")}</option>
                            {orgs.map((org, index) => (
                                <option key={index} value={org}>
                                    {org}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div style={{ // YEARS
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label>{t("Year")} :</label>
                        <select
                            value={selectedYear}
                            onChange={(e) => setSelectedYear(e.target.value)}
                            required
                            style={{
                                width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                borderRadius: '5px', outline: 'none',
                            }}
                        >
                            <option value="">{t("Select a year")}</option>
                            {years.map((years, index) => (
                                <option key={index} value={years}>
                                    {years}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div style={{ // EVENT
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label>Event :</label>
                        <select
                            value={eventName}
                            onChange={(e) => setEventName(e.target.value)}
                            required
                            style={{
                                width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                borderRadius: '5px', outline: 'none',
                            }}
                        >
                            <option value="">{t("Select an event")}</option>
                            {events.sort((a, b) => a.displayEventName?.localeCompare(b.displayEventName)).map((event, index) => (
                                <option key={index} value={event.id}>
                                    {event.displayEventName}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div style={{ // Combat
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label>Fight :</label>
                        <select
                            value={selectedCombat}
                            onChange={(e) => {
                                setSelectedCombat(e.target.value);
                                if (e.target.value !== "") {
                                    setFormulaireVisible(true);
                                }
                            }}
                            required
                            style={{
                                width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                borderRadius: '5px', outline: 'none',
                            }}
                        >
                            <option value="">{t("Select a fight")}</option>
                            {combats.map((combats, index) => (
                                <option key={index} value={combats}>
                                    {combats}
                                </option>
                            ))}
                        </select>
                    </div>

                </div>

                {formulaireVisible ?
                    <>
                        <div>
                            <button onClick={handleCancel} style={{ margin: '10px', backgroundColor: 'orange', color: 'white', padding: '10px 20px', borderRadius: '5px', fontWeight: 'bold', cursor: 'pointer' }}>
                                {t("Cancel the fight")}
                            </button>

                            <button onClick={handleDelete} style={{ margin: '10px', backgroundColor: 'red', color: 'white', padding: '10px 20px', borderRadius: '5px', fontWeight: 'bold', cursor: 'pointer' }}>
                                {t("Delete the fight")}
                            </button>

                        </div>

                        <form onSubmit={handleSubmit} style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 10 }}>

                            <div style={{ // COMBAT STATS ID
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>CombatStatsId :</label>

                                <div style={{
                                    width: '100%', marginBottom: '10px'
                                }}>
                                    <input
                                        type="text"
                                        value={combatStatsId}
                                        onChange={(e) => setCombatStatsId(e.target.value)}

                                        style={{
                                            width: '100%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                            borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                        }}
                                    />
                                </div>
                            </div>

                            <div style={{ // AMATEUR
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label style={{ marginRight: 10 }}>{t('Amateur')} :</label>

                                <select
                                    value={amateur}
                                    onChange={(e) => setAmateur(e.target.value === 'true')}
                                    required
                                    style={{
                                        width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none',
                                    }}
                                >
                                    <option value="">{t("Select an answer")}</option>
                                    {amateurs.map((amateur, index) => (
                                        <option key={index} value={amateur.value}>
                                            {amateur.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div style={{ // division
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>{t("Division")} :</label>
                                <select
                                    value={division}
                                    onChange={(e) => setDivision(e.target.value)}
                                    required
                                    style={{
                                        width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none',
                                    }}
                                >
                                    <option value="">{t("Select a division")}</option>
                                    {divisions.map((division, index) => (
                                        <option key={index} value={division.value}>
                                            {division.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {division === 'catch' ?
                                <div style={{ // IF CATCH WEIGHT
                                    width: '100%', marginBottom: '10px'
                                }}>

                                    <label>IF CATCHWEIGHT :</label>

                                    <div style={{
                                        width: '100%', marginBottom: '10px'
                                    }}>
                                        <input
                                            type="text"
                                            value={catchWeight}
                                            onChange={(e) => setCatchWeight(e.target.value)}
                                            required
                                            style={{
                                                width: '100%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                                borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                            }}
                                        />
                                    </div>
                                </div>
                                : null}

                            <div style={{ // NOM COMBATTANT 1
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>NAME FIGHTER 1 :</label>

                                <input
                                    type="text"
                                    value={search1}
                                    onChange={(e) => setSearch1(e.target.value)}
                                    placeholder={nom1 ? nom1 : "Search a fighter..."}
                                    style={{
                                        width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none',
                                    }}
                                />

                                {search1 && (
                                    <div style={{ width: '90%', border: '1.5px solid #ff3030', borderRadius: '5px', maxHeight: '150px', overflowY: 'scroll' }}>
                                        {filteredFighters1.map((fighter, index) => (
                                            <div
                                                key={index}
                                                style={{ padding: '10px', cursor: 'pointer' }}
                                                onClick={() => {
                                                    setNom1(fighter.id)
                                                    setSearch1('');  // optionnel: vider le champ de recherche lors de la sélection
                                                }}
                                            >
                                                {fighter.id}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>

                            <div style={{ // NOM COMBATTANT 2
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>NAME FIGHTER 2 :</label>
                                <input
                                    type="text"
                                    value={search2}
                                    onChange={(e) => setSearch2(e.target.value)}
                                    placeholder={nom2 ? nom2 : "Search a fighter..."}
                                    style={{
                                        width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none',
                                    }}
                                />

                                {search2 && (
                                    <div style={{ width: '90%', border: '1.5px solid #ff3030', borderRadius: '5px', maxHeight: '150px', overflowY: 'scroll' }}>
                                        {filteredFighters2.map((fighter, index) => (
                                            <div
                                                key={index}
                                                style={{ padding: '10px', cursor: 'pointer' }}
                                                onClick={() => {
                                                    setNom2(fighter.id)
                                                    setSearch2('');  // optionnel: vider le champ de recherche lors de la sélection
                                                }}
                                            >
                                                {fighter.id}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>

                            <button type="button" style={{
                                marginBottom: '10px',
                                padding: '10px 20px', border: 'none', backgroundColor: '#ff3030',
                                color: 'white', borderRadius: '25px', marginTop: '10px', cursor: 'pointer',
                                outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder'
                            }} onClick={switchNames}  >
                                Switch Names order
                            </button>

                            <div style={{ // COMBAT ID
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>Name of the fight (name vs name ( + number if they already face each other)) :</label>

                                <div style={{
                                    width: '100%', marginBottom: '10px'
                                }}>
                                    <input
                                        type="text"
                                        value={combatId}
                                        onChange={(e) => setCombatId(e.target.value)}
                                        required
                                        style={{
                                            width: '100%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                            borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                        }}
                                    />
                                </div>
                            </div>

                            <div style={{ // CARD
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>Card :</label>
                                <select
                                    value={selectedCard}
                                    onChange={(e) => setSelectedCard(e.target.value)}
                                    required
                                    style={{
                                        width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none',
                                    }}
                                >
                                    <option value="">{t("Select a card")}</option>
                                    {cards.map((cards, index) => (
                                        <option key={index} value={cards}>
                                            {cards}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div style={{ // CHAMP
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>Title fight ? :</label>
                                <select
                                    value={ifChamp}
                                    onChange={(e) => setIfChamp(e.target.value)}
                                    required
                                    style={{
                                        width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none',
                                    }}
                                >
                                    <option value="">{t("Select a fight type")}</option>
                                    {champ.map((champ, index) => (
                                        <option key={index} value={champ.value}>
                                            {champ.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div style={{ // MAIN EVENT
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>MAIN EVENT ? :</label>
                                <select
                                    value={ifMain}
                                    onChange={(e) => setIfMain(e.target.value)}
                                    required
                                    style={{
                                        width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none',
                                    }}
                                >
                                    <option value="">{t("Select a fight type")}</option>
                                    {main.map((main, index) => (
                                        <option key={index} value={main.value}>
                                            {main.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div style={{ // TOURNOI
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>TOURNAMENT ? :</label>
                                <select
                                    value={ifTournament}
                                    onChange={(e) => setIfTournament(e.target.value)}
                                    style={{
                                        width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none',
                                    }}
                                >
                                    <option value="">{t("Select a tournament phase")}:</option>
                                    {tournament.map((tournament, index) => (
                                        <option key={index} value={tournament.value}>
                                            {tournament.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div style={{ // NOMBRE DE ROUND
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>{t("HOW MANY ROUNDS")} :</label>

                                <div style={{
                                    width: '100%', marginBottom: '10px'
                                }}>
                                    <input
                                        type="text"
                                        value={numberOfRound}
                                        onChange={(e) => setNumberOfRound(e.target.value)}
                                        required
                                        style={{
                                            width: '100%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                            borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                        }}
                                    />
                                </div>
                            </div>

                            <div style={{ // ORDRE DANS LA CARTE
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>{t("ORDER IN THE CARD")} :</label>
                                <select
                                    value={order}
                                    onChange={(e) => setOrder(e.target.value)}
                                    required
                                    style={{
                                        width: '100%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none',
                                    }}
                                >
                                    <option value="">{t("Select an order")}</option>
                                    {orders.map((orders, index) => (
                                        <option key={index} value={orders.value}>
                                            {orders.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div style={{ // STATE du combat
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>state of the fight :</label>
                                <select
                                    value={state}
                                    onChange={(e) => setState(e.target.value)}
                                    required
                                    style={{
                                        width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none',
                                    }}
                                >
                                    <option value="">{t("Select the state of the fight")}</option>
                                    {states.map((states, index) => (
                                        <option key={index} value={states.value}>
                                            {states.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div style={{ // Win
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>WINNER :</label>
                                <select
                                    value={win}
                                    onChange={(e) => setWin(e.target.value)}
                                    required
                                    style={{
                                        width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none',
                                    }}
                                >
                                    <option value="">{t("Select a winner")}</option>
                                    {wins.map((wins, index) => (
                                        <option key={index} value={wins.value}>
                                            {wins.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div style={{ // Way
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>{t("RESULTS")} :</label>
                                <select
                                    value={way}
                                    onChange={(e) => setWay(e.target.value)}
                                    required
                                    style={{
                                        width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none',
                                    }}
                                >
                                    <option value="">{t("Select a result")}</option>
                                    {ways.map((ways, index) => (
                                        <option key={index} value={ways.value}>
                                            {ways.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div style={{ // ROUND
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>{t("In the round N°")}:</label>
                                <select
                                    value={round}
                                    onChange={(e) => setRound(e.target.value)}

                                    style={{
                                        width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none',
                                    }}
                                >
                                    <option value="">{("Select a round")}:</option>
                                    {rounds.map((rounds, index) => (
                                        <option key={index} value={rounds.value}>
                                            {rounds.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div style={{ // END TIME
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>END TIME :</label>

                                <div style={{
                                    width: '100%', marginBottom: '10px'
                                }}>
                                    <input
                                        type="text"
                                        value={endTime}
                                        onChange={(e) => setEndTime(e.target.value)}

                                        style={{
                                            width: '100%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                            borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                        }}
                                    />
                                </div>
                            </div>

                            <div style={{ // ARRAY COUNTRY
                                margin: '20px auto', padding: '20px', maxWidth: '400px', backgroundColor: '#f4f4f9', borderRadius: '8px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', fontFamily: 'Arial, sans-serif',
                            }}>
                                <h3 style={{ fontSize: '24px', textAlign: 'center', color: '#333', marginBottom: '20px', }}>Countries List</h3>

                                <ul style={{ listStyle: 'none', padding: 0 }}>
                                    {country?.map((country, index) => (
                                        <li key={index} style={{
                                            backgroundColor: '#fff', margin: '10px 0', padding: '10px', borderRadius: '4px', display: 'flex',
                                            justifyContent: 'space-between', alignItems: 'center', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                        }}>
                                            {country}
                                            <button type="button"
                                                style={{
                                                    backgroundColor: '#ff6b6b', border: 'none', color: 'white', padding: '8px 12px',
                                                    borderRadius: '4px', cursor: 'pointer', transition: 'background-color 0.3s ease',
                                                }}
                                                onClick={() => removeCountry(country)}
                                            >
                                                Remove
                                            </button>
                                        </li>
                                    ))}
                                </ul>

                                <AddCountryForm onAdd={addCountry} />
                            </div>

                            <button
                                type="submit"
                                style={{
                                    padding: '10px 20px', border: 'none', backgroundColor: '#ff3030',
                                    color: 'white', borderRadius: '25px', marginTop: '10px', cursor: 'pointer',
                                    outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder'
                                }}
                            >
                                {t("Submit")}
                            </button>
                        </form>


                    </>
                    : null}
            </div>
        </div >

    );
}

export function ListFightersCombat({ combattant1, setCombattant1, combattant2, setCombattant2, setLastName1, setLastName2, orgs }) {

    const { t } = useTranslation();

    const [orgLogos, setOrgLogos] = useState({}); // État pour stocker les logos des organisations

    useEffect(() => {
        const fetchLogos = async () => {
            const logosTemp = {}; // Stocker temporairement les logos

            for (const org of orgs) {
                const eventsDocRef = doc(db, "fightData", "organisations", org, "events");

                try {
                    const eventsDocSnap = await getDoc(eventsDocRef);

                    if (eventsDocSnap.exists()) {
                        const { logo } = eventsDocSnap.data(); // Supposons que 'logo' contient l'URL du logo
                        logosTemp[org] = logo; // Stocker l'URL du logo pour cette organisation
                    } else {
                        console.log(`Document 'events' non trouvé pour l'organisation: ${org}`);
                        logosTemp[org] = null; // Aucun logo trouvé
                    }
                } catch (error) {
                    console.error(`Erreur lors de la récupération du logo pour l'organisation: ${org}`, error);
                    logosTemp[org] = null; // En cas d'erreur, on stocke null
                }
            }

            setOrgLogos(logosTemp); // Mettre à jour l'état avec tous les logos récupérés
        };

        if (orgs && orgs.length > 0) {
            fetchLogos(); // Appeler la fonction pour récupérer les logos si la liste des orgs est disponible
        }
    }, [orgs]);

    const fetchFighters1 = async (search1, attributesToRetrieve = ['id']) => {
        const functions = getFunctions(app);
        const searchFighters = httpsCallable(functions, 'searchFighters');

        let fetchedData = [];

        try {
            // Créez une requête de recherche Algolia sans filtres
            const result = await searchFighters({
                search: search1,
                attributesToRetrieve
            });

            // Ajoutez tous les résultats de recherche à fetchedData
            if (result.data && Array.isArray(result.data.hits)) {
                fetchedData = result.data.hits.map(hit => ({
                    id: hit.id,
                    ...hit
                }));
            } else {
                console.log('No results from Algolia search or data structure is unexpected.');
            }
        } catch (error) {
            console.error('Error loading favorite fighters data:', error);
        }


        return fetchedData;
    };



    const handleFighterClick = (fighter) => {
        const fighterId = fighter ? fighter.id : null;
        const fighterLastName = fighter ? fighter.lastName : null;
        if (!combattant1 || combattant2) {
            setCombattant1(fighterId);
            setCombattant2(null);
            setLastName1(fighterLastName);
            setLastName2(null);
        } else {
            setCombattant2(fighterId);
            setLastName2(fighterLastName);
        }
    };

    const LoadingSpinner = () => {
        return (
            <div className="spinner"></div>
        );
    };

    const [inputFighters, setInputFighters] = useState("");
    const [fighterStatus, setFighterStatus] = useState({});
    const [fighterData, setFighterData] = useState({});
    const [listLoading, setListLoading] = useState(false);

    const handleFighterListSubmit = async () => {
        setListLoading(true);
        const fighterNames = inputFighters.split('\n');
        const status = {};
        const fighterData = {};

        for (const name of fighterNames) {
            const results = await fetchFighters1(name.trim());
            status[name] = results.length > 0 ? results[0] : null; // Stockez l'objet du combattant ou null si non trouvé

            if (results.length > 0) {

                const fighterId = results[0].id; // Supposons que results[0] est l'ID du combattant

                const docRef = doc(db, "fighterData", fighterId); // Référence au document dans la collection "fighterData"

                try {
                    const docSnap = await getDoc(docRef); // Récupérer les données du document Firestore

                    if (docSnap.exists()) {
                        fighterData[fighterId] = docSnap.data(); // Stocker les données du combattant dans le nouveau state
                    } else {
                        fighterData[fighterId] = null; // Aucun document trouvé dans Firestore
                    }
                } catch (error) {
                    console.error("Erreur lors de la récupération du document Firestore: ", error);
                    fighterData[fighterId] = null; // En cas d'erreur, on stocke null
                }
            }
        }

        setFighterStatus(status); // Mettre à jour l'état avec les statuts des combattants
        setFighterData(fighterData); // Mettre à jour l'état avec les données Firestore des combattants
        setListLoading(false);
    };


    const divisions = [{ value: "1heavy", label: "HEAVYWEIGHT" },
    { value: "2lightheavy", label: "LIGHT-HEAVYWEIGHT" },
    { value: "3middle", label: "MIDDLEWEIGHT" },
    { value: "4welter", label: "WELTERWEIGHT" },
    { value: "5light", label: "LIGHTWEIGHT" },
    { value: "6feather", label: "FEATHERWEIGHT" },
    { value: "7bantam", label: "BANTAMWEIGHT" },
    { value: "8fly", label: "FLYWEIGHT" },
    { value: "9straw", label: "STRAWWEIGHT" },
    { value: "10atom", label: "ATOMWEIGHT" },
    { value: "catch", label: "CATCH WEIGHT" }];


    return (
        <div style={{
            display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px',
            fontFamily: "'Arial', sans-serif", justifyContent: 'center', borderRadius: '10px',
            width: '110%', margin: '50px auto',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            backgroundColor: '#f7f7f7'
        }}>
            <h2 style={{ color: '#ff3030', marginBottom: '20px' }}>{t("Check the fighters")}</h2>

            <textarea
                value={inputFighters}
                onChange={(e) => setInputFighters(e.target.value)}
                placeholder="Entrez les noms des combattants, un par ligne"
                style={{
                    width: '100%', minHeight: '150px', padding: '15px', marginBottom: '20px',
                    border: '1px solid #ddd', borderRadius: '5px', fontFamily: 'inherit',
                    fontSize: '16px', resize: 'vertical'
                }}
            />
            <button onClick={handleFighterListSubmit} style={{
                padding: '10px 20px', backgroundColor: '#ff3030', color: 'white',
                border: 'none', borderRadius: '25px', cursor: 'pointer', fontSize: '16px',
                fontWeight: 'bold', marginBottom: '20px', transition: 'background-color 0.3s'
            }}>
                Search
            </button>

            {!listLoading ?
                <div style={{ width: '100%' }}>
                    {Object.entries(fighterStatus).map(([name, fighter]) => {
                        const fighterId = fighter?.id; // Vérifier l'ID du combattant
                        const fighterInfo = fighterId ? fighterData[fighterId] : null; // Récupérer les infos du combattant depuis fighterData
                        const orgaId = fighterInfo?.orga; // Récupérer l'organisation du combattant
                        const orgaLogo = orgaId ? orgLogos[orgaId] : null; // Récupérer le logo de l'organisation si disponible

                        return (
                            <div key={name}
                                style={{
                                    display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                                    padding: '10px', borderBottom: '1px solid #eee', fontSize: '16px',
                                    backgroundColor: fighter && fighter.id === combattant1 ? 'lightgreen' :
                                        fighter && fighter.id === combattant2 ? 'lightblue' : ''
                                }}
                                onClick={() => handleFighterClick(fighter)}>

                                <div style={{ display: 'flex', alignItems: 'center', }}>
                                    <span>
                                        {/* Affichage du logo ou du texte de l'organisation */}
                                        {orgaLogo ? (
                                            <div style={{
                                                width: '50px', height: '50px', backgroundColor: "#252525", alignItems: 'center', display: 'flex',
                                                justifyContent: 'center', borderRadius: '5px'
                                            }}>
                                                <img src={orgaLogo} alt={`${fighterInfo?.orga} logo`} style={{ width: '40px', }} />
                                            </div>

                                        ) : (
                                            <div style={{
                                                width: '50px', height: '50px', backgroundColor: "#252525", alignItems: 'center',
                                                justifyContent: 'center', borderRadius: '5px', display: 'flex',
                                            }}>
                                                <span style={{ fontSize: '13px', color: 'white', alignSelf: 'center' }}>{fighterInfo?.orga || '!'}</span>
                                            </div>
                                        )}
                                    </span>

                                    {fighterInfo && fighterInfo.photo ? (
                                        <div style={{
                                            width: '50px', height: '50px', alignItems: 'flex-end', display: 'flex',
                                            justifyContent: 'center',
                                        }}>
                                            <img src={fighterInfo.photo} alt={`${fighterInfo?.id} picture`} style={{ width: '70px', marginLeft: '20px' }} />
                                        </div>
                                    ) : fighterInfo && !fighterInfo.photo ? (
                                        <div style={{
                                            width: '50px', height: '50px', alignItems: 'flex-end', display: 'flex',
                                            justifyContent: 'center',
                                        }}>
                                            <img src={NoFighterImage} alt="NoFighterImage" style={{ width: '70px', marginLeft: '20px' }} />
                                        </div>
                                    )
                                        : null}

                                    <span style={{ marginLeft: '20px' }}>

                                        {fighterInfo ? (
                                            <span>
                                                <span style={{ fontWeight: 'bold', display: 'inline-block' }}>
                                                    {fighterInfo.firstName} {fighterInfo.lastName}
                                                    {fighterInfo.nickname && (
                                                        <span style={{ fontStyle: 'italic', marginLeft: '10px', fontWeight: 'normal', }}>
                                                            ({fighterInfo.nickname})
                                                        </span>
                                                    )}
                                                </span>

                                                {fighterInfo.division && (
                                                    <div style={{ fontSize: '11px', color: '#404040', marginTop: '3px' }}>
                                                        {
                                                            // Rechercher le label correspondant à la division
                                                            divisions.find(division => division.value === fighterInfo.division)?.label || fighterInfo.division
                                                        }
                                                    </div>
                                                )}
                                            </span>
                                        ) : (
                                            name
                                        )}
                                    </span>
                                </div>

                                <span style={{ color: fighter ? 'green' : 'red' }}>
                                    {fighter ? '✅' : '❌'}
                                </span>
                            </div>
                        );
                    })}
                </div>
                : <LoadingSpinner />}

        </div>
    )
}

export function SeeCard() {
    const { t } = useTranslation();
    const [events, setEvents] = useState([]);
    const [eventName, setEventName] = useState("");
    const [eventData, setEventData] = useState([]);
    const [combatId, setCombatId] = useState("");
    const [nom1, setNom1] = useState("");
    const [nom2, setNom2] = useState("");
    const [numberOfRound, setNumberOfRound] = useState("");
    const [org, setOrg] = useState("");
    const years = ['2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030'];
    const currentYear = new Date().getFullYear();
    const [selectedYear, setSelectedYear] = useState(currentYear.toString());
    const [orgs, setOrgs] = useState([]);
    const cards = eventData.ifEarlyPrelims ? ['main card', 'prelims', 'early prelims'] : ['main card', 'prelims'];
    const [selectedCard, setSelectedCard] = useState('');

    const [stopTime, setStopTime] = useState(0);

    useEffect(() => { // RÉCUPÉRATION ORGA
        const fetchOrgs = async () => {

            const orgsDocRef = doc(db, "fightData", "organisations");

            try {
                const orgsDocSnap = await getDoc(orgsDocRef);

                if (orgsDocSnap.exists()) {
                    const { listOrgasNames } = orgsDocSnap.data(); // Assurez-vous que 'listOrgasNames' est correctement initialisé dans votre document
                    setOrgs(listOrgasNames);
                } else {
                    console.log("Document 'organisations' non trouvé.");
                }
            } catch (error) {
                console.error("Erreur lors de la récupération du document:", error);
            }
        };

        fetchOrgs();
    }, []);

    // Charger tous les events

    useEffect(() => { // RÉCUPÉRATION EVENTS
        const fetchEvents = async () => {
            if (selectedYear && org) {
                const querySnapshot = await getDocs(collection(db, "fightData", "organisations", org, "events", selectedYear));
                const events = [];
                querySnapshot.forEach((document) => {
                    const data = document.data();
                    events.push({
                        id: document.id,               // Document ID
                        displayEventName: data.displayEventName || document.id  // Fallback to ID if displayEventName doesn't exist
                    });
                });
                setEvents(events);
            };
        };
        fetchEvents();
    }, [selectedYear, org]);

    // Charger les données de l'event sélectionné
    useEffect(() => {
        const fetchEventData = async () => {
            if (selectedYear && org && eventName) {
                const docRef = doc(db, "fightData", "organisations", org, "events", selectedYear, eventName);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setEventData(data);
                } else {
                    console.log("No such document!");
                }
            }
        };

        fetchEventData();
    }, [selectedYear, eventName, org]);


    const [timeMainCard, setTimeMainCard] = useState('');
    const [timePrelims, setTimePrelims] = useState('');
    const [timeEarlyPrelims, setTimeEarlyPrelims] = useState('');

    useEffect(() => { // RÉCUPÉRATION TIME 
        if (eventData) {
            setTimeMainCard(eventData.maincardtime || '')
            setTimePrelims(eventData.prelimstime || '')
            setTimeEarlyPrelims(eventData.earlyprelimstime || '')
        }
    }, [eventData]);

    ///////////////// RÉCUPÉRATION DES COMBATS
    const [combats, setCombats] = useState([]);
    const [selectedCombat, setSelectedCombat] = useState('');


    useEffect(() => {
        const fetchCombats = () => {
            if (selectedYear && org && eventName) {
                const unsubscribe = onSnapshot(
                    collection(db, "fightData", "organisations", org, "events", selectedYear, eventName, 'card'),
                    (querySnapshot) => {
                        const combatGroups = {
                            'main card': [],
                            'prelims': [],
                            'early prelims': [],
                            'cancelled': []
                        };

                        querySnapshot.forEach((doc) => {
                            const combatData = doc.data();
                            combatGroups[combatData.card].push({
                                id: doc.id,
                                ...combatData
                            });
                        });

                        Object.keys(combatGroups).forEach(card => {
                            combatGroups[card].sort((a, b) => a.order.localeCompare(b.order, undefined, { numeric: true }));
                        });

                        setCombats(combatGroups);
                    }
                );

                return () => unsubscribe(); // Désabonnement lors du démontage du composant
            }
        };

        return fetchCombats(); // Appel et retour de la fonction de désabonnement
    }, [selectedYear, org, eventName]);


    const onDragEnd = (result) => {
        const { source, destination } = result;

        // Vérifier si l'élément a été déplacé vers une destination valide
        if (!destination) {
            return;
        }

        const sourceCard = source.droppableId;
        const destCard = destination.droppableId;
        const sourceIndex = source.index;
        const destIndex = destination.index;

        // Créer une copie profonde de l'état actuel pour éviter de modifier l'état directement
        let newCombatGroups = JSON.parse(JSON.stringify(combats));

        // Cas 1: Déplacement au sein de la même 'card'
        if (sourceCard === destCard) {
            const movedItem = newCombatGroups[sourceCard][sourceIndex];
            newCombatGroups[sourceCard].splice(sourceIndex, 1);
            newCombatGroups[sourceCard].splice(destIndex, 0, movedItem);
        }
        // Cas 2: Déplacement vers une autre 'card'
        else {
            const movedItem = newCombatGroups[sourceCard][sourceIndex];
            newCombatGroups[sourceCard].splice(sourceIndex, 1);
            newCombatGroups[destCard].splice(destIndex, 0, movedItem);
            movedItem.card = destCard; // Mettre à jour la 'card' du combat déplacé
        }

        // Mettre à jour l'état local
        setCombats(newCombatGroups);


    };

    const [isMainEventDefined, setIsMainEventDefined] = useState(true);

    const handleToggleMainEvent = () => {
        setIsMainEventDefined(prevState => !prevState);
    };

    const updateDatabaseWithNewOrder = async (newCombats) => {
        const batch = writeBatch(db); // Utilisez un batch pour les mises à jour atomiques


        // Vérifiez s'il y a au moins un combat qui a card === 'prelims'
        let hasPrelims = false;
        Object.keys(newCombats).forEach(card => {
            if (card === 'prelims' && newCombats[card].length > 0) {
                hasPrelims = true;
            }
        });

        // Ajoutez la mise à jour pour ifPrelims
        const eventRef = doc(db, "fightData", "organisations", org, "events", selectedYear, eventName);
        batch.update(eventRef, { ifPrelims: hasPrelims });


        Object.keys(newCombats).forEach(card => {
            newCombats[card].forEach((combat, index) => {
                // Déterminer la valeur de l'ordre
                let orderValue;
                if (index === newCombats[card].length - 1) {
                    // Si c'est le dernier élément, assignez 'first'
                    orderValue = 'first';
                } else {
                    // Sinon, utilisez l'index + 1 (car les index commencent à 0)
                    orderValue = (index + 1).toString();
                }



                const combatRef = doc(db, "fightData", "organisations", org, "events", selectedYear, eventName, 'card', combat.id);
                let updateData = {
                    order: orderValue, // order on the card
                    card: card, // which card
                    date: card === 'main card' ? timeMainCard : card === 'prelims' ? timePrelims : card === 'early prelims' ? timeEarlyPrelims : timeMainCard,
                    main: isMainEventDefined ? (card === 'main card' && orderValue === '1' ? 1 : card === 'main card' && orderValue === '2' ? 2 : 0) : 0, // MAIN EVENT OR CO MAIN EVENT
                };

                // Ajout de la condition pour 'state'
                if (card === 'cancelled') { // if the fight is cancelled and for example it was on imminent start, put it back to innitial state (To not fetch it when fetching the live fight on the app)
                    updateData.state = 0;
                    updateData.main = 0;
                }

                // Mise à jour du batch avec les données conditionnelles
                batch.update(combatRef, updateData);

            });
        });

        await batch.commit();
    };



    return (
        <div style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'
        }}>
            <div style={{ backgroundColor: '#ff3030', padding: '20px', borderRadius: 10 }}>

                <div style={{ // Organisation
                    width: '100%', marginBottom: '10px'
                }}>
                    <label>Organization :</label>
                    <select
                        value={org}
                        onChange={(e) => setOrg(e.target.value)}
                        required
                        style={{
                            width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                            borderRadius: '5px', outline: 'none',
                        }}
                    >
                        <option value="">{t("Select an organization")}</option>
                        {orgs.map((org, index) => (
                            <option key={index} value={org}>
                                {org}
                            </option>
                        ))}
                    </select>
                </div>

                <div style={{ // YEARS
                    width: '100%', marginBottom: '10px'
                }}>
                    <label>{t("Year")} :</label>
                    <select
                        value={selectedYear}
                        onChange={(e) => setSelectedYear(e.target.value)}
                        required
                        style={{
                            width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                            borderRadius: '5px', outline: 'none',
                        }}
                    >
                        <option value="">{t("Select a year")}</option>
                        {years.map((years, index) => (
                            <option key={index} value={years}>
                                {years}
                            </option>
                        ))}
                    </select>
                </div>

                <div style={{ // EVENT
                    width: '100%', marginBottom: '10px'
                }}>
                    <label>Event :</label>
                    <select
                        value={eventName}
                        onChange={(e) => setEventName(e.target.value)}
                        required
                        style={{
                            width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                            borderRadius: '5px', outline: 'none',
                        }}
                    >
                        <option value="">{t("Select an event")}</option>
                        {events.sort((a, b) => a.displayEventName?.localeCompare(b.displayEventName)).map((event, index) => (
                            <option key={index} value={event.id}>
                                {event.displayEventName}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', fontFamily: "'Arial', sans-serif", justifyContent: 'center',
                borderRadius: '10px', width: '400px', margin: '50px auto',
            }}>

                {Object.keys(combats)?.length > 0 ?
                    <>
                        <div style={{
                            display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', width: '400px',

                        }}>
                            <span style={{ marginRight: 10, fontWeight: 'bold' }}>Main event defined ?</span>

                            <div onClick={handleToggleMainEvent}
                                style={{
                                    width: '50px', height: '30px', backgroundColor: isMainEventDefined ? '#fd2121' : 'grey',
                                    borderRadius: '30px', display: 'flex', alignItems: 'center',
                                    padding: '3px', cursor: 'pointer', transition: 'background-color 0.3s ease',
                                }} >
                                <div
                                    style={{
                                        width: '25px', height: '25px', backgroundColor: '#fff',
                                        borderRadius: '50%', transition: 'transform 0.3s ease',
                                        transform: isMainEventDefined ? 'translateX(25px)' : 'translateX(0)',
                                    }}
                                ></div>
                            </div>

                            {isMainEventDefined ?
                                <span style={{ marginLeft: 10 }}>YES</span>
                                : <span style={{ marginLeft: 10 }}>NO</span>}

                        </div>
                    </>
                    : null}
                <DragDropContext onDragEnd={onDragEnd}>
                    {Object.keys(combats).map((cardType) => (
                        <Droppable droppableId={cardType} key={cardType}>
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef} style={{
                                    backgroundColor: '#f5f5f5', // Couleur de fond
                                    borderRadius: '10px', // Bords arrondis
                                    padding: '10px', // Espacement intérieur
                                    width: '100%', // Largeur
                                    margin: '10px 0', // Marge extérieure
                                    // Autres styles...
                                }}>
                                    <h3 style={{ textTransform: 'capitalize', color: cardType === 'cancelled' ? 'orange' : 'black' }}>{cardType}</h3> {/* Affiche le type de carte */}
                                    {combats[cardType].map((combat, index) => (
                                        <Draggable key={combat.id} draggableId={combat.id} index={index}>
                                            {(provided) => (
                                                <div

                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}


                                                >
                                                    {combat.order} {combat.id}
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    ))}
                </DragDropContext>




                {Object.keys(combats)?.length > 0 ?
                    <button onClick={() => { updateDatabaseWithNewOrder(combats) }}
                        style={{
                            padding: '10px 20px', marginTop: '20px', backgroundColor: 'red', border: 'none',
                            color: 'white', cursor: 'pointer', fontWeight: 'bolder', borderRadius: '30px',
                        }}>
                        {t("Submit")}
                    </button>
                    : null}

            </div>
        </div >

    );
}

const AddCountryForm = ({ onAdd }) => {
    const [newCountry, setNewCountry] = useState('');

    const handleAdd = (e) => {
        e.preventDefault();
        onAdd(newCountry);
        setNewCountry(''); // Réinitialise le champ de saisie après l'ajout
    };

    return (
        <div style={{
            display: 'flex', marginTop: '20px',
        }}>
            <input
                type="text" value={newCountry}
                onChange={(e) => setNewCountry(e.target.value)}
                placeholder="Add a new country"
                style={{
                    flex: 1, padding: '10px', border: '1px solid #ccc', borderRadius: '4px', marginRight: '10px', boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
                }}
            />
            <button type="button" onClick={handleAdd} style={{
                backgroundColor: '#4caf50', border: 'none',
                color: 'white', padding: '10px 15px', borderRadius: '4px',
                cursor: 'pointer', transition: 'background-color 0.3s ease',
            }}>
                Add
            </button>
        </div>
    );
};